<script setup lang="ts">
defineProps(["modelValue"]);
const emit = defineEmits(["update:modelValue"]);
function close() {
  emit("update:modelValue", false);
}
</script>

<template>
  <Teleport to="body">
    <div
      v-if="modelValue"
      class="fixed left-0 top-0 z-20 h-screen w-full overflow-y-auto"
    >
      <div class="relative flex h-full justify-center p-4">
        <div
          class="absolute left-0 top-0 h-full w-full bg-[#0D1017] opacity-80"
          @click="close"
        ></div>
        <div class="relative my-auto" :class="$attrs.class">
          <slot />
        </div>
      </div>
    </div>
  </Teleport>
</template>
